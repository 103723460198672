<template>
  <el-container>
    <el-main>
      <div>
          <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
           @click="addType"
        >添加分类</el-button>
      </div>
      <el-form class="sesrch">
        <el-form-item label="论坛分类：">
             <el-input size="small" v-model="form.name" placeholder="请输入搜索分类"></el-input>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-select
            size="small"
            v-model="form.is_show"
            placeholder="请选择"
          >
            <el-option
              v-for="item in show"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-button
            size="small"
            type="primary"
            @click="getList()"
          >搜索</el-button>
          <el-button
            size="small"
            type="primary"
          >导出</el-button>
          <el-button
            size="small"
            type="text"
            @click="cancelSearch"
          >清空搜索条件</el-button>
        </el-form-item>

      </el-form>

      <div class="article-list">
        <el-table :data="list"
          :header-cell-style="{ 'background-color': '#F8F9FA' }"
        >
          <template slot="empty">
            <No />
          </template>
          <el-table-column
            prop="name"
            label="分类名称"
            align="center"
          ></el-table-column>
            <el-table-column
            prop="sort"
            label="组内权重"
            align="center"
          >
            <template #header>
                <div class="edit">
                    <span>组内权重</span>
                    <i class="el-icon-edit-outline"></i>
                 </div>
            </template>
            <template slot-scope="scope">
                <el-input type="number" :min="0" size="small" v-model="scope.row.sort" @change="tableChange(scope.row)"
                class="tableinput"></el-input>
            </template>
        </el-table-column>

        <el-table-column
            prop="image"
            label="图片"
            align="center"
        >
            <template slot-scope="scope">
                <img :src="scope.row.image" alt="img" style="width:50px;height:50px;" onerror="javascript:this.src = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/orderDefault.png'">
            </template>
        </el-table-column>

          <el-table-column
            prop="is_show"
            label="是否显示"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.is_show"
                :active-value="1"
                :inactive-value="0"
                @change="tableChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>

          <el-table-column
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="editType(scope.row)"
                type="text"
                size="small"
              >编辑</el-button>
              <el-button
                @click="remove(scope.row.id)"
                type="text"
                size="small"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
       <el-dialog
            :title="isEdit ? '编辑分类' : '添加分类'"
            :visible.sync="showAddType"
            width="30%"
            :before-close="handleClose"
    >
      <el-form ref="addForm" class="addType" :model="addForm" :rules="rules" label-width="100px">

        <el-form-item label="分类名称：" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="分类图片：" required prop="image">
          <ReadyUploadSource
            :showStyle="{
              width: '110px',
              height: '110px'
            }"
            :path="addForm.image"
            @getSource="getImg"
            @removeThis="removeImg"
          ></ReadyUploadSource>
          <span style="color: #999;">建议图片尺寸800px*800px，大小不超过500kb</span>
        </el-form-item>
        <el-form-item label="分类排序：" prop="sort">
          <el-input v-model="addForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-switch v-model="addForm.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddType = !1">取 消</el-button>
        <el-button type="primary" @click="confirmForm">确 定</el-button>
      </span>
    </el-dialog>
      <Paging
        :total="total"
        :page="page"
        :pageNum="pageNum"
        @updatePageNum="updateData"
      ></Paging>
    </el-main>
  </el-container>
</template>

<script>
import Paging from '@/components/paging.vue'
import ReadyUploadSource from '@/components/readyUploadSource'
export default {
  components: {
    Paging,
    ReadyUploadSource
  },
  data () {
    return {
      page: 1,
      pageNum: 10,
      total: 0,
      form:{
            name:'',
            is_show:-1
        },
        show: [
            {
                value: -1,
                label: '全部'
            },
            {
                value: 1,
                label: '是'
            },
            {
                value: 0,
                label: '否'
            }
        ],
        typeList: [],
        list: [],
        showAddType:false,
        isEdit:false,
        addForm: {
            name: '',
            image: '',
            sort: 0,
            is_show: 1,
        },
      rules: {
        name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ],
        sort:[{
            required: true,
            message: '请输入分类权重',
            trigger: 'blur'
        }],
        image:[{
            required: true,
            message: '请上传分类图片',
            trigger: 'blur'
        }]
      }
    }
  },
  created () {
    let utils = this.$store.state.utils
    if (utils.is_record) {
      this.page = utils.page
      this.pageNum = utils.rows
      utils.is_record = !1
    }
    this.getList()
  },
  methods: {
    cancelSearch () {
      this.form = {
        name:'',
        is_show:-1
      }
      this.getList()
    },
    updateData (val, status) {
      if (status == 0) {
        this.pageNum = val
        this.getList()
      } else {
        this.page = val
        this.getList()
      }
    },
    getList () {
        this.$axios
            .post(this.$api.samecity.forum.classifyList, {
                page: this.page,
                rows: this.pageNum,
                name: this.form.name,
                is_show: this.form.is_show
            })
            .then((res) => {
            if (res.code == 0) {
                let list = res.result.list
                this.list = list.list
                this.total = list.total
            } else {
                this.$message.error(res.msg)
            }
        })
    },
    detail(row) {
      this.$router.push({
        path: '/extension/forum/detail',
        query: {
          info: JSON.stringify(row),
          page: this.page,
          rows: this.pageNum
        }
      })
    },
    remove (id) {
      this.$confirm('确认删除此分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .post(this.$api.samecity.forum.classifyDelete, {
              id: id
            })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '删除成功',
                  type: 'success'
                })
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
        })
        .catch((err) => { })
    },
    tableChange (row) {
      this.$axios.post(this.$api.samecity.forum.classifyEdit, row).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    addType() {
      this.showAddType = true
      this.isEdit = false
      this.addForm = {
        name: '',
        image: '',
        sort: 0,
        is_show: 1
      }
    },
    editType(row) {
      this.showAddType = true
      this.isEdit = true
      this.addForm = row
    },
     getImg(val) {
      this.addForm.image = val.path
    },
    removeImg() {
      this.addForm.image = ''
    },
    confirmForm(){
        this.$refs['addForm'].validate((valid) => {
            if (valid) {
                if(this.isEdit){
                    this.$axios.post(this.$api.samecity.forum.classifyEdit, this.addForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '编辑成功',
                                type: 'success'
                            })
                            this.showAddType = false
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }else{
                    this.$axios.post(this.$api.samecity.forum.classifyAdd, this.addForm).then((res) => {
                        if (res.code == 0) {
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            })
                            this.showAddType = false
                            this.getList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }
            }
        })
    },
    handleClose(){
        this.$refs.addForm.resetFields()
        this.showAddType = false
    }
  }
}
</script>

<style lang="less" scoped>
.el-table {
    border-left: 1px solid #e8eef1;
    border-right: 1px solid #e8eef1;
    /deep/ .el-input__inner {
        border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
        border-color: #409eff;
    }
}
.el-container {
    .el-main {
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        .sesrch {
            margin-top: 15px;
            margin-bottom: 10px;
            padding: 10px 15px;
            background: #f8f9fa;
            display: flex;
            .el-form-item {
                margin-bottom: 0;
                margin-right: 20px;
                display: flex;
                .el-input {
                    width: 250px;
                }
                .el-select {
                    width: 250px;
                }
                /deep/.el-form-item__label{
                    width:100px;
                }
            }
        }
        .article-list {
            flex: 1;
        }
    }
}
.table_title{
    text-align: left;
     text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.edit {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
    i {
      font-size: 17px;
    }
  }
  .tableinput{
      width: 120px;
      /deep/input{
          text-align: center;
      }
  }
</style>
